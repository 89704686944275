<template>
  <div class="brandDetail">
    <el-image
      class="banner"
      :src="require('@as/serve/brand/banner.png')"
    ></el-image>

    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1"><a href="#">服务简介</a></el-menu-item>
      <el-menu-item index="2"><a href="#">我们的案例</a></el-menu-item>
      <el-menu-item index="3"><a href="#">我们的优势</a></el-menu-item>
      <el-menu-item index="4"><a href="#">服务流程</a></el-menu-item
      ><el-menu-item index="5"><a href="#">联系我们</a></el-menu-item>
    </el-menu>

    <div class="section">
      <div class="title">服务简介</div>
      <div class="subtitle">
        服务于法律机构2B及2C客户的品牌公关构建，提供战略定位或大事件公关期策略及配套全案执行
      </div>
      <div class="detail">
        <el-image class="img" :src="require('@as/serve/banner.png')"></el-image>
        <div class="info">
          <div class="row">
            HERO高校联盟是华为云2019年面向高等院校的师生开发者们开放的社区平台，旨在通过此平台连接高校师生和华为云，通过知识共享、活动交流、实践项目建设等，帮助高校师生在云服务领域进行学习和创新。
          </div>
          <div class="row">
            2019年，来自全国各地的高校大学生们通过HERO高校联盟，创建百余个高校兴趣联盟，将华为云高校行、云计算学习课堂、学生专享活动带入校园，连接高校师生。
          </div>
        </div>
      </div>
    </div>

    <div class="container gray">
      <div class="section gray">
        <div class="title">我的的案例</div>
        <div class="carousel">
          <div class="item" v-for="(item, index) in 4" v-bind:key="item">
            <div class="item_main">
              <el-image
                class="item_img"
                :src="require('@as/serve/brand/banner.png')"
              ></el-image>
              <div class="item_card" v-if="index == selectCarpusel">
                <div class="item_card_title">云计算知识专业培训</div>
                <div class="item_card_subtitle">法律公关</div>
                <div class="item_card_divider"></div>
                <div class="item_card_detail">
                  019年，来自全国各地的高校大学生们通过HERO高校联盟，创建百余个高校兴趣联盟，将华为云高校行、云计算学习课堂、学生专享活动带入校园，连接高校师
                </div>
              </div>
            </div>
            <div class="item_title">云计算知识专业培训</div>
            <div class="item_subtitle">法律公关</div>
          </div>
          <el-image
            class="carousrl_icon_left"
            :src="require('@as/serve/brand/icon_28.png')"
            @click="selectChange('left')"
          ></el-image>
          <el-image
            class="carousrl_icon_right"
            :src="require('@as/serve/brand/icon_29.png')"
            @click="selectChange('right')"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title">我们的优势</div>
      <div class="card_container">
        <div class="card">
          <el-image
            class="card_img"
            :src="require('@as/serve/brand/icon_13.png')"
          ></el-image>
          <div class="card_info">
            <div class="card_title">云计算知识专业培训</div>
            <div class="card_detail">
              导师是一个联盟的高级顾问，每个联盟可拥有多名导师，享有导师各项权益并承担联盟指导职责
            </div>
          </div>
        </div>
        <div class="card">
          <el-image
            class="card_img"
            :src="require('@as/serve/brand/icon_14.png')"
          ></el-image>
          <div class="card_info">
            <div class="card_title">云计算知识专业培训</div>
            <div class="card_detail">
              导师是一个联盟的高级顾问，每个联盟可拥有多名导师，享有导师各项权益并承担联盟指导职责
            </div>
          </div>
        </div>
        <div class="card">
          <el-image
            class="card_img"
            :src="require('@as/serve/brand/icon_15.png')"
          ></el-image>
          <div class="card_info">
            <div class="card_title">云计算知识专业培训</div>
            <div class="card_detail">
              导师是一个联盟的高级顾问，每个联盟可拥有多名导师，享有导师各项权益并承担联盟指导职责
            </div>
          </div>
        </div>
        <div class="card">
          <el-image
            class="card_img"
            :src="require('@as/serve/brand/icon_16.png')"
          ></el-image>
          <div class="card_info">
            <div class="card_title">云计算知识专业培训</div>
            <div class="card_detail">
              导师是一个联盟的高级顾问，每个联盟可拥有多名导师，享有导师各项权益并承担联盟指导职责
            </div>
          </div>
        </div>
        <div class="card">
          <el-image
            class="card_img"
            :src="require('@as/serve/brand/icon_17.png')"
          ></el-image>
          <div class="card_info">
            <div class="card_title">云计算知识专业培训</div>
            <div class="card_detail">
              导师是一个联盟的高级顾问，每个联盟可拥有多名导师，享有导师各项权益并承担联盟指导职责
            </div>
          </div>
        </div>
        <div class="card">
          <el-image
            class="card_img"
            :src="require('@as/serve/brand/icon_18.png')"
          ></el-image>
          <div class="card_info">
            <div class="card_title">云计算知识专业培训</div>
            <div class="card_detail">
              导师是一个联盟的高级顾问，每个联盟可拥有多名导师，享有导师各项权益并承担联盟指导职责
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flow_container">
      <div class="title">服务流程</div>
      <div class="flow">
        <div class="item">
          <el-image
            class="flow_icon"
            :src="require('@as/serve/brand/icon_19.png')"
          ></el-image>
          <div class="flow_name">需求沟通</div>
        </div>
        <div class="item">
          <el-image
            class="flow_icon"
            :src="require('@as/serve/brand/icon_20.png')"
          ></el-image>
          <div class="flow_name">需求调研</div>
        </div>
        <div class="item">
          <el-image
            class="flow_icon"
            :src="require('@as/serve/brand/icon_21.png')"
          ></el-image>
          <div class="flow_name">需求策划</div>
        </div>
        <div class="item">
          <el-image
            class="flow_icon"
            :src="require('@as/serve/brand/icon_22.png')"
          ></el-image>
          <div class="flow_name">需求设计</div>
        </div>
        <div class="item">
          <el-image
            class="flow_icon"
            :src="require('@as/serve/brand/icon_23.png')"
          ></el-image>
          <div class="flow_name">需求解决</div>
        </div>
        <div class="item">
          <el-image
            class="flow_icon"
            :src="require('@as/serve/brand/icon_24.png')"
          ></el-image>
          <div class="flow_name">需求回访</div>
        </div>
      </div>
    </div>
    <div class="call_me">
      <div class="title">联系我们</div>
      <div class="card_container">
        <div class="card">
          <div class="card_title">贴心服务热线</div>
          <el-image
            class="card_icon"
            :src="require('@as/serve/brand/icon_25.png')"
          ></el-image>
          <div class="card_phone">15001223819</div>
        </div>
        <div class="card">
          <div class="card_title">服务邮箱</div>
          <el-image
            class="card_icon"
            :src="require('@as/serve/brand/icon_27.png')"
          ></el-image>
          <div class="card_phone">15001223819@mail.com</div>
        </div>
        <div class="card">
          <div class="card_title">客服服务微信</div>
          <el-image
            class="card_icon"
            :src="require('@as/serve/brand/icon_26.png')"
          ></el-image>
          <div class="card_phone">15001223819</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
      selectCarpusel: 0,
    }
  },

  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    selectChange(res) {
      if (res == 'left') {
        this.selectCarpusel =
          this.selectCarpusel == 0 ? 3 : this.selectCarpusel - 1
      } else {
        console.log(this.selectCarpusel == 3)
        this.selectCarpusel =
          this.selectCarpusel == 3 ? 0 : this.selectCarpusel + 1
      }
      console.log(this.selectCarpusel)
    },
  },
}
</script>

<style lang="less" scoped>
.brandDetail {
  background: #fff;
}
.banner {
  width: 100%;
  height: 400px;
  display: block;
}
.menu {
  display: flex;
  justify-content: center;
}
.el-menu {
  width: 100%;
  display: flex;
  justify-content: center;
}
.container {
  &.gray {
    background: #f8f8f8;
  }
}
.section {
  margin: 0 auto;
  padding: 50px 0 60px;
  width: 1200px;
  text-align: center;
  .title {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .subtitle {
    margin-top: 16px;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #999999;
  }
  .detail {
    margin-top: 40px;
    display: flex;
    .img {
      flex-shrink: 0;
      width: 540px;
      height: 304px;
    }
    .info {
      margin: 33px 0 0 47px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #777777;
      line-height: 24px;
      .row {
        margin-bottom: 40px;
        text-align: left;
      }
    }
  }
  .carousel {
    position: relative;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .carousrl_icon_left {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .carousrl_icon_right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .item {
      text-align: left;
      .item_main {
        position: relative;
        width: 280px;
        height: 350px;
        display: flex;
        align-items: flex-end;
        .item_img {
          width: 280px;
          height: 320px;
        }
        .item_card {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          padding: 55px 20px;
          font-family: PingFangSC;
          color: #ffffff;
          text-align: left;
          box-sizing: border-box;
          background: linear-gradient(0deg, #51999d, #4892b1);
          .item_card_title {
            font-size: 18px;
            font-weight: 400;
          }
          .item_card_subtitle {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #dedede;
          }
          .item_card_divider {
            margin: 18px 0 24px;
            width: 40px;
            height: 2px;
            background: #fff;
          }
          .item_card_detail {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
      .item_title {
        margin-top: 20px;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .item_subtitle {
        margin-top: 12px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .card_container {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    .card {
      padding: 40px 30px;
      display: flex;
      align-items: flex-start;
      width: 380px;
      height: 200px;
      border: 1px solid #e7e7e7;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      &:not(:nth-child(3n)) {
        margin-right: 30px;
      }
      &:nth-child(-n + 3) {
        margin-bottom: 30px;
      }
      .card_img {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
      }
      .card_info {
        margin-top: 6px;
        margin-left: 30px;
        text-align: left;
        .card_title {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #333333;
        }
        .card_detail {
          margin-top: 28px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
      }
    }
  }
}
.flow_container {
  padding: 48px 0;
  height: 360px;
  text-align: center;
  background: center/100% 100% url('~@as/serve/brand/flow_bg.png') no-repeat;
  box-sizing: border-box;
  .title {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  .flow {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      &:not(:first-child) {
        margin-left: 84px;
      }
      .flow_icon {
        width: 120px;
        height: 120px;
      }
      .flow_name {
        margin-top: 16px;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
.call_me {
  padding: 50px;
  text-align: center;
  background: #383d42;
  opacity: 0.9;
  .title {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .card_container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .card {
      padding: 32px 0;
      width: 340px;
      height: 165px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      background: #5e6266;
      box-sizing: border-box;
      &:not(:first-child) {
        margin-left: 60px;
      }
      .card_icon {
        margin: 10px 0;
        width: 48px;
        height: 48px;
      }
    }
  }
}
& /deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #4c9bbb;
}
</style>